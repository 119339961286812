import styled from "styled-components";
import {
  BP,
  Colors,
  Rem,
} from "../../commons/Theme";
import { StyledGridRow } from "../../commons/Grid";

export const StyledCommunity = styled.div`
  background-color: ${Colors.white};

  ${StyledGridRow} {
    padding-top: ${Rem(80)};
    padding-bottom: ${Rem(60)};

    @media (${BP.ipad}) {
      padding-top: ${Rem(100)};
      padding-bottom: ${Rem(80)};
    }
  }
`;

export const StyledCommunityTitle = styled.h2`
  text-transform: uppercase;
  font-size: ${Rem(14)};
  padding-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    font-size: ${Rem(15)};
    letter-spacing: ${Rem(5)};
  }
`;

export const StyledCommunitySubTitle = styled.h3`
  font-size: ${Rem(30)};
  line-height: ${Rem(37)};
  padding-bottom: ${Rem(48)};

  @media (${BP.ipad}) {
    font-size: ${Rem(50)};
    line-height: ${Rem(55)};
    position: absolute;
    top: ${Rem(98)};
    left: calc(50% + ${Rem(95)});
    max-width: ${Rem(480)};
  }
`;

export const StyledCommunityParagraph = styled.p`
  font-size: ${Rem(16)};
  line-height: ${Rem(24)};
  padding-bottom: ${Rem(32)};

  @media (${BP.ipad}) {
    width: 50%;
    padding-bottom: ${Rem(50)};
  }
`;

export const StyledCommunityItems = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
`;

export const StyledCommunityItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-width: 50%;
  max-width: 50%;
  margin-bottom: ${Rem(24)};

  @media (${BP.ipad}) {
    min-width: 0;
    max-width: none;
  }
`;

export const StyledCommunityItemImage = styled.img`
  width: ${Rem(80)};
  margin-bottom: ${Rem(14)};
`;

export const StyledCommunityItemCount = styled.div`
  font-size: ${Rem(20)};
  line-height: ${Rem(25)};
  padding-bottom: ${Rem(6)};

  @media (${BP.ipad}) {
    font-size: ${Rem(35)};
  }
`;

export const StyledCommunityItemLabel = styled.div`
  font-size: ${Rem(18)};
  line-height: ${Rem(24)};
`;
