import React, {
  useContext, useEffect, useState, 
} from "react";
import {
  StyledBeekeepersMap,
  StyledBeekeepersWrapper,
  StyledHivesButton,
  StyledHivesText,
} from "./style";
import { useLocation } from "@reach/router";
import { DataContext } from "../../contexts/DataContext";
import { LayoutContext } from "../../contexts/LayoutContext";
import Button from "../Button/Button";
import useApi from "../../hooks/useApi";
import OpenLayers from "../OpenLayers/OpenLayers";
import Community from "../Community/Community";
import ApiErrorForm from "../ApiErrorForm/ApiErrorForm";

export default ({
  expand = true, purchaser, coupon, b2bHive = 1, markers = [], kpiVisibility = true, center, zoom,
}) => {
  const { getAdoptedHives } = useApi();
  const { labels, i18nOasiLink } = useContext(DataContext);
  const [hivesAdopted, setHivesAdopted] = useState([]);
  const [kpi, setKpi] = useState([{
    image: "/2020/images/adotta/cuore.svg",
    count: "4.050",
    label: "porpo", 
  }]);
  const { setModalContent } = useContext(LayoutContext);
  const [urlParams, setUrlParams] = useState((""));
  const location = useLocation();
  
  useEffect(() => {
    const _urlParams = new URLSearchParams(location.search);
    _urlParams.append("parentUrl", location.pathname.substring(1));
    setUrlParams(_urlParams);

    setKpi([{
      image: "/2020/svg/icons/bee.svg",
      count: b2bHive * 300,
      label: "Mila Api Protette", 
    },
    {
      image: "/2020/images/adotta/fiore.svg",
      count: b2bHive * 300,
      label: "Milioni Fiori Impollinati", 
    },
    {
      image: "/2020/svg/icons/hive.svg",
      count: b2bHive,
      label: "Alveari Monitorati", 
    },
    {
      image: "/2020/images/adotta/fiore.svg",
      count: b2bHive * 2,
      label: "Fioriture impollinate", 
    }]);

    if (expand) {
      getAdoptedHives(purchaser, coupon).then((res) => {
        if (res.error) {
          setModalContent(<ApiErrorForm message={res.message} />);
          console.log("error getting Hives");
        } else {
          let total_adopted_qty = 0;
          let totalHoney = 0;
          var arrHives = [];
          var honeyHives = [];
          var noHoneyHives = [];

          for (var key in res) {
            res[key].forEach((x) => {
              x.slug = key.replace(/\./g, "-").toLowerCase();
              x.slug = x.slug.replace(/ /g, "-");
              arrHives.push(x);
              var atleastoneHoney = 0;

              for (var honey in x.honeys) {
                if (x.honeys[honey].av_quantity > 0.5) { atleastoneHoney = 1; }
              }
              if (atleastoneHoney === 1 && x.latitude>1) { honeyHives.push(x); } else { noHoneyHives.push(x); }
              if (x.adopted_qty) {
                total_adopted_qty = total_adopted_qty + x.adopted_qty;
              } else {
                x.adopted_qty = 0;
              }
              if (x.honeys.length) {
                totalHoney = totalHoney + x.honeys.length;
              }
            });
          }
          setHivesAdopted(honeyHives);
          //setKpi([{image: '/2020/svg/icons/bee.svg', count: total_adopted_qty*2, label: 'Mila Api Monitorate'},
          //{image: '/2020/images/adotta/fiore.svg', count: total_adopted_qty*0.2*6, label: 'Milioni Fiori Impollinati'},
          //{image: '/2020/svg/icons/hive.svg', count: 0, label: 'Alveari Monitorati'},
          //{image: '/2020/images/adotta/fiore.svg', count:  0, label: 'Fioriture impollinate'}])

          /*if (res.length === 0) {
            setIsMissingBeekeepers(true)
          } else {
            setIsMissingBeekeepers(false)
          }*/
        }
        //setIsLoadingBeekeepers(false)
      });
    }
  }, [location, b2bHive, coupon, expand, getAdoptedHives, setModalContent, purchaser]);

  return (
    <>
      <StyledBeekeepersWrapper>
        {expand && coupon && (
          <StyledHivesButton>
            <StyledHivesText>
            Codice sconto {coupon}
            </StyledHivesText>
            <Button as="div" to={i18nOasiLink(`/alveari/?coupon=${coupon}`)}>
            Espandi l'apiario
            </Button>
          </StyledHivesButton>
        )}
        <StyledBeekeepersMap>
          <OpenLayers
            center={center}
            zoom={zoom}
            markers={[
              ...hivesAdopted.map((x) => {
                let urlPin = "/2020/images/biodiversity/pin.png";
                const link = i18nOasiLink(`/grower/${x.slug}/?${urlParams}`);
                const name = x.name;

                return {
                  pin: urlPin,
                  center: {
                    lat: x.latitude,
                    lng: x.longitude,
                  },
                  scale: 1,
                  infowindow: `<strong>${name}</strong><br/><a href='${link}'>Sceglimi</a>`,
                };
              }),
              ...markers.map(marker => {
                var info="a";
                if (marker.infowindow != null){
                  info = marker.infowindow;
                } else
                {
                  const link = i18nOasiLink(`/grower/${marker.beekeeper_name}/?${urlParams}`);

                  info = `<strong>${marker.alveare_nome}</strong><br/><a href='${link}'>${labels.CHOOSEME}</a>`;
                }
                return {
                  pin: marker.icon,
                  center: {
                    lat: marker.lat,
                    lng: marker.lng,
                  },
                  infowindow: info,
                };
              }),
            ]}
          />
        </StyledBeekeepersMap>
      </StyledBeekeepersWrapper>
      {kpiVisibility && (
        <Community title="Le Api" subtitle='I nostri <span class="color-yellow">numeri</span>' items={kpi}></Community>)
      }
    </>
  );
};
